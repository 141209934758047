<template>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-button
          style="float: right"
          type="primary"
          @click="dialogFormVisible = true"
          >添加门诊</el-button
        >
      </div>
      <el-dialog title="添加门诊" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="门诊名称" :label-width="formLabelWidth">
            <el-input v-model="form.clinicname" autocomplete="off" placeholder="请输入门诊名称"></el-input>
          </el-form-item>
          <el-form-item label="门诊地址" :label-width="formLabelWidth">
            <el-input v-model="form.address" autocomplete="off" placeholder="请输入门诊地址"></el-input>
          </el-form-item>
          <el-form-item label="门诊描述" :label-width="formLabelWidth">
            <el-input v-model="form.dec" autocomplete="off" placeholder="请输入门诊描述"></el-input>
          </el-form-item>
          <el-form-item label="经度" :label-width="formLabelWidth">
            <el-input v-model="form.longitude" autocomplete="off" placeholder="请输入经度，经度通过腾讯地图获得，其它地图经度不准"></el-input>
          </el-form-item>
          <el-form-item label="维度" :label-width="formLabelWidth">
            <el-input v-model="form.latitude" autocomplete="off" placeholder="请输入维度，经度通过腾讯地图获得，其它地图维度不准"></el-input>
          </el-form-item>
          <el-form-item label="是否显示" :label-width="formLabelWidth">
            <el-radio v-model="form.isshow" label="true">显示</el-radio>
            <el-radio v-model="form.isshow" label="false">不显示</el-radio>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addclinic()"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!--修改-->
      <el-dialog title="修改门诊" :visible.sync="editdialogFormVisible">
        <el-form :model="editform">
          <el-form-item label="门诊名称" :label-width="formLabelWidth">
            <el-input v-model="editform.clinicname" autocomplete="off" placeholder="请输入门诊名称"></el-input>
          </el-form-item>
          <el-form-item label="门诊地址" :label-width="formLabelWidth">
            <el-input v-model="editform.address" autocomplete="off" placeholder="请输入门诊地址"></el-input>
          </el-form-item>
          <el-form-item label="门诊描述" :label-width="formLabelWidth">
            <el-input v-model="editform.dec" autocomplete="off" placeholder="请输入门诊描述"></el-input>
          </el-form-item>
          <el-form-item label="经度" :label-width="formLabelWidth">
            <el-input v-model="editform.longitude" autocomplete="off" placeholder="请输入经度，经度通过腾讯地图获得，其它地图经度不准"></el-input>
          </el-form-item>
          <el-form-item label="维度" :label-width="formLabelWidth">
            <el-input v-model="editform.latitude" autocomplete="off" placeholder="请输入维度，经度通过腾讯地图获得，其它地图维度不准"></el-input>
          </el-form-item>
          <el-form-item label="是否显示" :label-width="formLabelWidth">
            <el-radio-group v-model="editform.isshow">
                <el-radio :label="true">显示</el-radio>
                <el-radio :label="false">不显示</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editdialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="editoneclinic(editform)"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!--修改-->
      <el-table
        :data="items"
        style="width: 100%; margin-bottom: 20px"
        row-key="_id"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="clinicname" label="门诊名称" width="120" > </el-table-column>
        <el-table-column prop="address" label="门诊地址"></el-table-column>
        <el-table-column prop="dec" label="门诊描述" width="180"> </el-table-column>
        <el-table-column prop="longitude" label="经度" width="120"></el-table-column>
        <el-table-column prop="latitude" label="维度" width="120"> </el-table-column>
        <el-table-column
            prop="tag"
            label="是否显示"
            width="100"
            >
            <template slot-scope="scope">
            <el-tag
                :type="scope.row.isshow === true ? 'primary' : 'success'"
                disable-transitions>{{scope.row.isshow === true ? '显示' : '不显示'}}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="date" label="添加时间" width="180"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleedit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handlePageChange"
        @size-change="handlePageSizeChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </template>
  <script>
  
  export default {
    data() {
      return {
        editdialogFormVisible: false,
        dialogFormVisible: false,
        formLabelWidth: "120px",
        currentPage: 1, // 当前页码
        pageSize: 5, // 每页数据量
        total: 0, // 总数据量
        items: [],
        parents: [],
        form: {
          clinicname: "",
          address: "",
          dec: "",
          longitude: "",
          latitude: "",
          isshow:"",
        },
        editform: {
          _id:"",
          clinicname: "",
          address: "",
          dec: "",
          longitude: "",
          latitude: "",
          isshow:"",
        },
        checkoptions: [],
        tableData: [],
      };
    },
    methods: {
      editoneclinic(data){
        this.$api.clinic.editclinic(data).then(res =>{
          this.$message({
            message:res.data.msg,
            type: 'success'
          });
          this.loadItems()
          this.editdialogFormVisible = false;
        })
      },
      handleedit(data){
        console.log(data)
        this.editdialogFormVisible = true;
        const {_id,clinicname,address,dec,longitude,latitude,isshow} = data
        this.editform._id = _id
        this.editform.clinicname = clinicname
        this.editform.address = address
        this.editform.dec= dec
        this.editform.longitude= longitude
        this.editform.latitude= latitude
        this.editform.isshow= isshow
      },
      handleDelete(data){
        this.$api.clinic.deleteclinic(data).then(res =>{
          this.$message({
            message:res.data.msg,
            type: 'success'
          });
          this.loadItems()
        })
      },
      addclinic() {
        const data = {
          clinicname: this.form.clinicname,
          address: this.form.address,
          dec: this.form.dec,
          longitude: this.form.longitude,
          latitude: this.form.latitude,
          isshow: this.form.isshow,
        }
        //console.log(this.form.parent)
        this.$api.clinic.createapplet(data).then(res =>{
          this.$message({
            message:res.data.msg,
            type: 'success'
          });
          this.dialogFormVisible = false
          this.loadItems();
          this.form = {}
        })
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.loadItems();
      },
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.loadItems();
      },
      loadItems(){
        const data = {
          currentPage: this.currentPage,
          pageSize:this.pageSize
        }
        this.$api.clinic.getpagination(data).then(res =>{
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        })
      },
    },
    created(){
      this.loadItems()
    }
  };
  </script>
  <style>
  .text {
    font-size: 14px;
  }
  
  .item {
    margin-bottom: 18px;
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  
  .box-card {
    width: 100%;
  }
  </style>  